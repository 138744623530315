
import { defineComponent } from "vue";
import { useResetPassword } from "@/graphql/user/reset.password";
import { useForgotPassword } from "@/graphql/user/forgot-password";
import FieldErrors from "@/components/common/FieldErrors.vue";

export default defineComponent({
  name: "ResetPassword",
  components: { FieldErrors },
  setup() {
    const { submit: resentCode, session } = useForgotPassword();
    return {
      ...useResetPassword(),
      resentCode,
      session,
    };
  },
});
