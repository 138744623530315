import { useMutation } from "@vue/apollo-composable";
import { MutationResetPasswordArgs, User } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useToast } from "primevue/usetoast";
import { useValidation } from "vue3-form-validation";
import { useI18n } from "vue-i18n";
import { ForgotPasswordSession } from "@/graphql/user/forgot-password";
import { useRouter } from "vue-router";
import { CONSTANTS } from "@/graphql/utils/utils";

type ResetPasswordData = {
  resetPassword: User;
};
const UPDATE_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      id
    }
  }
`;

export const useResetPassword = () => {
  const session: ForgotPasswordSession = JSON.parse(
    sessionStorage.getItem(CONSTANTS.forgotPassword)
  );
  const { t } = useI18n();
  const { push } = useRouter();
  const { validateFields, hasError, form } = useValidation({
    code: {
      $value: null,
      $rules: [(m: any) => String(m).length !== 6 && t("validation.code")],
    },
    password: {
      $value: "",
      $rules: [
        (n: string) => !n && t("validation.required"),
        {
          key: "pw",
          rule: (p: string, c: string) =>
            p !== c && t("signUp.validation.password"),
        },
      ],
    },
    confirm: {
      $value: "",
      $rules: [
        (n: string) => !n && t("validation.required"),
        {
          key: "pw",
          rule: (p: string, c: string) =>
            p !== c && t("signUp.validation.password"),
        },
      ],
    },
  });
  const toast = useToast();
  const { loading, mutate, onDone, onError } = useMutation<
    ResetPasswordData,
    MutationResetPasswordArgs
  >(UPDATE_PASSWORD);
  onDone(({ data, errors }) => {
    if (data?.resetPassword) {
      toast.add({
        severity: "success",
        summary: t("resetPassword.title"),
        detail: t("resetPassword.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      sessionStorage.removeItem(CONSTANTS.forgotPassword);
      setTimeout(
        () => void push(`${localStorage.getItem(CONSTANTS.appSource)}/sign-in`),
        2000
      );
    } else {
      toast.add({
        severity: "warn",
        summary: t("resetPassword.title"),
        detail: t(`resetPassword.${errors[0].message}`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("forgotPassword.sendCode"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  function submit() {
    validateFields().then((value) => {
      const { password, code } = value;
      void mutate({ input: { password, code, email: session.email } });
    });
  }
  return { loading, hasError, form, submit };
};
